/* Show All Deal Dialog */

.show-all-deal-dialog {
    background-color: #00000000 !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;

}

.show-all-deal-dialog .MuiDialog-paper {
    background-color: #fff !important;
    max-width: 900px;
    width: 900px;

}

.show-all-deals-header {
    padding-bottom: 4px !important;
}

.show-all-deals-header .hotel-details {
    margin-top: 8px;
    display: flex;
    align-items: self-start;
    gap: 1rem
}

.show-all-deals-header .hotel-details .hotel-image {
    width: 130px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px
}

.show-all-deals-header .hotel-details .hotel-content {
    width: 100%;
    line-height: 1.55
}

.show-all-deals-header .hotel-details .hotel-content .destination-section,
.show-all-deals-header .hotel-details .hotel-content .rating-section {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    gap: 5px
}

.show-all-deals-header .hotel-title {
    font-size: 20px;
    margin-bottom: 5px;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deals-header .hotel-details .hotel-content .title {
    color: #000;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 22px;
    font-weight: normal;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deals-header .icon {
    width: 16px;
}

.show-all-deals-title-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 10px;
    background-color: #e5feff;
    border-radius: 8px;
    margin-top: 12px;
}

.show-all-deals-title-and-filters .show-all-deals-title {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deals-filters {
    display: flex;
    align-items: center;
    gap: 1rem
}

.show-all-deals-filters .sort-by-filter .filter-form {
    background: #fff;
    border-radius: 4px;
    border-width: 2px;
    border-color: #c6c6c6;
}

.show-all-deals-filters .sort-by-filter .filter-form .filter-select {
    width: 165px;
    height: 38px;
    font-size: 13px;
    font-weight: 500;
    color: #5e5e5e;
    line-height: 24px;
}

.filter-select-menuItem {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    min-height: auto !important;
}


.filter-select-menuItem.Mui-selected,
.stay-filters-dropdown .MuiMenu-list.Mui-selected .ListItemText,
.depart-filters-dropdown .MuiMenu-listMui-selected .ListItemText{
    color: #26c6da !important;
}
.filter-select-menuItem:hover {
    color: #26c6da !important;
}


.filter-select-menuItem .ListItemText .MuiListItemText-primary {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;

}

.filter-select-Checkbox {
    width: 22px;
    height: 22px;
    margin-right: 12px !important;
}

.filter-select-Checkbox svg {
    width: 0.9em;
    height: 0.9em;
}

.Mui-selected.filter-select-menuItem {
    background: transparent !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.Mui-selected.filter-select-menuItem .filter-select-Checkbox svg {
    color: #28c6da;

}

.show-all-deals-filters .sort-by-filter .filter-form .filter-label {
    background: #fff;
    padding-right: 4px;
    padding-left: 4px;
    margin-left: -4px;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
    transform: translate(14px, -9px) scale(0.75);
    z-index: 1;
    pointer-events: auto;
}

.show-all-deal-dialog .filter-checkbox {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.show-all-deal-dialog .show-all-deals-content {
    padding-top: .5rem !important;
    max-height: calc(100svh - 280px) !important
}

.show-all-deal-dialog .deal-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: .5rem;
    flex-wrap: nowrap;
}

.deal-item-container .deal-item-part-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    gap: 2px;
    flex-wrap: wrap;
}

.show-all-deal-dialog .date-range-container {
    display: flex;
    align-items: start;
    gap: 5px;
    color: #000;
}

.deal-item-container .deal-date-range .date-range {
    font-size: 14px !important;
    font-weight: 500;
    color: #2f2f2f !important;
}

.show-all-deal-dialog .date-range-container .date-range {
    font-size: 13px;
    color: #000;
    display: flex;
    gap: 0px;
    flex-wrap: wrap;
    white-space: nowrap;
}

.show-all-deal-dialog .deal-item-container.is-recommended .deal-date-range .date-range {
    color: #db487e !important;
}

.show-all-deal-dialog .deal-item-container .nights-flights {
    font-size: 14px;
    color: #2f2f2f !important;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

.show-all-deal-dialog .deal-item-container .nights-flights {
    width: 40%;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deal-dialog .deal-item-container .flights-and-hotels-text {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deal-dialog .deal-item-container .deal-item-part-right {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: .5rem;
    flex-wrap: nowrap;
}

.show-all-deal-dialog .deal-item-container .price-container {
    display: flex;
    align-items: center;
    text-align: center;
    display: block !important;
    line-height: 1.2;
    margin-left: auto;
}

.show-all-deal-dialog .deal-item-container .price-container .price {
    color: #db487e;
    font-size: 16px;
    margin-right: 4px;
    font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif !important;
    font-weight: 700 !important;
}

.show-all-deal-dialog .deal-item-container .price-container .per-person {
    font-size: 11px;
    color: #454545;
    line-height: 1.1;
}

.show-all-deal-dialog .deal-item-container .price-container .per-person {
    color: #2f2f2f !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deal-dialog .deal-item-container .deal-item-part-right .book-btn-container {
    margin-left: auto;
}


.show-all-deal-dialog .book-btn {
    border: 2px solid #25c9d0;
    background-color: transparent !important;
    box-shadow: none;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    border-radius: 4px;
    padding: 4px 18px;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deal-dialog .show-all-deal-dialog .book-btn:hover {
    border: 2px solid #25c9d0;
    background-color: #25c9d0 !important;
}

.show-all-deal-dialog .custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #dddddd;
}

.show-all-deal-dialog .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.show-all-deal-dialog .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    height: 20px;
    background-color: #919191 !important;
}

.show-all-deal-dialog .DialogActions {
    position: absolute;
    top: 0;
    right: 0;
}

.show-all-deal-dialog .DialogActions button {
    min-width: auto;
    color: #7f7f7f;
}

.show-all-deal-dialog .DialogActions button:hover {
    color: #000
}

.show-all-deals-content .disclaimer {
    padding-bottom: 20px;
}

.show-all-deals-content .taxes-fee {
    font-size: 12px;
    text-align: center;
    color: #454545;
    margin: 1rem 0 5px;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.show-all-deals-content .package-price-text {
    font-size: 12px;
    text-align: center;
    color: #454545;
    margin-bottom: 1rem;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.deal-item-container .nights-flights {
    font-size: 14px;
    color: #2f2f2f !important;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

.deal-item-container .nights-flights {
    width: 40%;
}

.deal-item-container .nights-flights .flights-and-hotels-icons {
    display: none;
    padding-top: 2px;
}

.deal-item-container .nights-flights .flights-and-hotels-icons .icon {
    width: 18px;
    margin-right: 4px;
}

.deal-item-container .nights-flights .flights-and-hotels-icons .mat-icon {
    height: 18px;
    width: 20px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.deal-item-container .nights-flights .flights-and-hotels-text {
    display: block;
}


/* Mobile view css  */

@media (max-width: 420px) {
    .show-all-deal-dialog .show-all-deals-content {
        /* zoom: 75%; */
    }

    .show-all-deal-dialog .show-all-deals-header {
        zoom: 65%;
    }

    .show-all-deals-filters .sort-by-filter .filter-form,
    .show-all-deals-filters .nights-filter .mat-mdc-form-field,
    .show-all-deals-filters .depart-day-filter .mat-mdc-form-field {
        /* width: 150px */
    }
}



@media (max-width: 576px) {
    .deal-item-container .nights-flights {
        width: 100%;
    }

    .deal-item-container .nights-flights .flights-and-hotels-icons {
        display: block;
    }

    .deal-item-container .nights-flights .flights-and-hotels-text {
        display: none;
    }

    .deal-item-container .deal-item-part-left {
        width: 40%;
    }

    .deal-item-container .deal-item-part-right {
        width: 55% !important;
    }

    .deal-item-container .deal-item-part-left,
    .deal-item-container .deal-item-part-right {
        width: 40%;
    }

    .show-all-deal-dialog .show-all-deals-header {
        zoom: 78%;
    }

    .show-all-deals-filters {
        /* gap: 1.5rem; */
        zoom: 80%;

    }

    .sort-filters-dropdown {}

    .stay-filters-dropdown {
        /* transform: scale(1.2) !important;
        transform-origin: top right !important; */
    }

    .depart-filters-dropdown {
        /* transform: scale(1.2) !important;
        transform-origin: -900px -203px !important; */
    }

    .show-all-deals-filters .sort-by-filter .filter-form .filter-label {
        /* transform: translate(10px, -25px) scale(0.75) */
    }

}

@media screen and (max-width: 768px) {
    .show-all-deal-dialog {
        zoom: 90% !important;
    }

    .show-all-deal-dialog .show-all-deals-header {
        zoom: 90%;
    }

    .show-all-deal-dialog .show-all-deals-content {
        /* zoom: 80%; */
        zoom: 63%;
    }

    .show-all-deal-dialog .deal-item-container .price-container .price {
        font-size: 14px;
    }

    .show-all-deal-dialog .book-btn {
        font-size: 14px;
    }

    .show-all-deal-dialog .show-all-deals-header {
        margin-top: 16px;
    }

    .filter-select-menuItem .ListItemText .MuiListItemText-primary {
        font-size: 14px;
    }

    .filter-select-Checkbox svg {
        width: 0.6em;
        height: 0.6em;
    }

    .show-all-deals-filters .sort-by-filter .filter-form .filter-label {
        padding-left: 8px;
        padding-right: 8px;
    }

    .depart-filters-dropdown {
        transform: scale(1.2) !important;
        /* transform-origin: -900px -553px !important; */
        transform-origin:-900px -300px !important;

        
    }
}


@media screen and (max-width:900px) {
    .show-all-deal-dialog .MuiDialog-paper {
        margin: 0;
        zoom: 95%;
        max-width: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        height: 100vh;
        border-radius: 0;
        max-height: 100%;
    }
}



/* droupdonw issue in adrom moibile */

@media screen and (min-width: 768px) and (max-width: 900px) {
    .depart-filters-dropdown {
        top: 196px !important;
        left: 745px !important;
    }

}


@media screen and (min-width: 468px) and (max-width: 576px) {
    .depart-filters-dropdown {
        transform-origin:-1500px -553px !important
    }

}

@media screen and (min-width: 320px) and (max-width: 468px) {
    .depart-filters-dropdown {
        transform-origin: -900px -553px !important;
    }

}




@supports (-webkit-touch-callout: none) {

    @media only screen and (max-width: 768px) {
        /* Styles for iOS devices with screen width 768px or smaller */

        .stay-filters-dropdown {
            transform: scale(1.2) !important;
            transform-origin: top right !important;
        }

        .filter-select-menuItem {
            min-height: 48px !important;
        }

        .depart-filters-dropdown {
            transform: scale(1.2) !important;

            transform-origin: -900px -203px !important;
        }

        .show-all-deals-filters .sort-by-filter .filter-form .filter-label {
            transform: translate(10px, -25px) scale(0.75)
        }
    }

    @media screen and (min-width: 768px) and (max-width: 900px) {
        .depart-filters-dropdown {
            left: 670px !important;
        }
    
    }   
}