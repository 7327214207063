.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "color emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #F3F3F3 !important;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.custom-price-range-clss .MuiSlider-valueLabel {
  font-size: 0.75rem;
  padding: 0.2rem;
}

/*** Header css ****/
.banner-strip-top {
  background-color: #ffe200; /* Gray background */
  color: #000000; /* White text color */
  padding: 10px; /* Add some padding */
  text-align: center; /* Center the text */
  font-size: 14px; /* Adjust font size */
  /* font-weight: bold; Make text bold */
  /* position: fixed; Fix the banner to the top of the page */
  /* top: 0; Position it at the very top */
  /* left: 0; */
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
}
.header-section {
  background: linear-gradient(90deg, #25C9D0 10%, #fbee77 35%, #DB487E 90%);
  /* background: linear-gradient(to right, #25C9D0, #fbee77, #DB487E); */
  box-shadow: 0 0 3px #bcbcbc;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  padding-bottom: 3px;
  /* margin-top: 30px; */
}

.header-section-child-container-cls {
  width: 100%;
  background-color: #fff;
}
.mt-140{
  margin-top: 140px !important;
}
.header-section .logo {
  width: 120px;
  cursor: pointer;
}

.header-section .logo img {
  max-width: 100%;
}

.hero-section {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), Url("../src/assets/img/cc-banner.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -25px;
}

.hero-section .home-heading {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 4rem;
}

.hero-section .home-heading .beta-btn {
  background: #DB487E;
  border-radius: 27px;
  font-size: 1.2rem;
  color: #fff;
  padding: 2px 1rem;
  margin-left: 1rem;
}

.hero-section sup {
  top: -0.5em;
}

.hero-section .hero-search-section {
  padding: 7rem 0rem;
}

.hero-section .hero-search-section .search-card {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(3px);
  background: rgba(204, 252, 255, .5803921569);
  height: 100%;
  cursor: pointer;
  padding: 2rem 1rem 2rem;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  box-shadow: #00000026 0 15px 25px, #0000000d 0 5px 10px;
}

.hero-section .hero-search-section .search-card:hover {
  box-shadow: #00000029 0 1px 4px;
}

.hero-section .hero-search-section .search-card-icon {
  font-size: 5rem;
  margin: 1rem;
  color: #006c6e;
}

.hero-section .hero-search-section .search-card-title {
  font-size: 20px;
  text-shadow: 0px 0px 1px #000000;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0.5rem;
}

.hero-section .hero-search-section .search-card-desc {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.hero-section .home-heading {
  font-size: 3rem;
  margin-bottom: 4rem;
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 3.5rem;
}




/* start spinner */
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

/* end spinner */


.leftSideBar {
  background: #fff;
  height: calc(100vh - 60px);
  width: 0px;
  transition: width 0.3s ease-in-out;
}

.leftSideBar.open {
  width: 300px !important;
}

.leftSideBar .newChat,
.leftSideBar .newChat:hover {
  padding: 8px;
  border-radius: 16px;
  border: 1px solid #25C9D0;
  background: #25C9D0;
  font-size: 12px;
}


.menuList .ListItem {
  color: #2A3950;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px 8px;
  margin: 0 !important;
}

.ListItemActive {
  border: 1px solid #25C9D0;
  color: #25C9D0 !important;
}

.menuList .ListItem .ListItemText span {
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: transform 0.3s ease-in-out;
}

.menuList .ListItem:hover span {
  transform: translateX(5px);
  color: #25C9D0;

}

.listheading span {
  color: #343232;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}

.travelHelp {
  bottom: 0;
  width: 100%;
  margin-top: 8rem;
}

.travelHelpcontainer {
  width: 100%;
  margin: 0 auto;
}

.travelHelp .hedding h2 {
  color: #2A3950;
  font-size: 18px;
  font-weight: 600;

}

.h6-title {
  margin-bottom: 8px;
  font-weight: 600;
}

.travelHelp .listButton {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  font-size: 14px;
  padding: 10px 18px;
  display: table-cell;
  float: left;
  width: 100%;
}

.travelHelp .listButton.active {
  border: 1px solid #25C9D0;
}

.listdots .List {
  display: flex;
  width: 300px;
  margin: 0 auto;
}

.chaticon img {
  margin-right: 8px;
}

.chatIcon {
  min-width: auto !important;
}

.chatIcon img {
  width: 24px;
}

.chatBg {
  background-color: #fff;
  box-shadow: 0px 2px 6px #dfdfdf;
  padding: 4px;
  display: inline-grid;
  border-radius: 0px 18px 18px 18px;
  border: 1px solid #E7E7E7;
  color: #171A1F;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
}


.chatBg h6 {
  font-size: 15px;
  color: #2A3950;
  margin-bottom: 4px;
}

.chatBg .clr-grey {
  color: #999;
  font-size: 11px;
}

.chatBg2 {
  background-color: #fff;
  box-shadow: 0px 2px 6px #dfdfdf;
  padding: 14px;
  border-radius: 18px 0px 18px 18px;
  display: inline-grid;
  float: right;

  h6 {
    font-size: 15px;
    color: #2A3950;
  }

  p {
    font-size: 12px;
    margin: 0px;
  }

  p .clr-grey {
    color: #999;
  }
}

.nav-right {
  box-shadow: 1px 1px 5px #e0e0e0 !important;
}

.listdots .dots {
  height: 8px;
  background: #D6D4D2;
  border-radius: 10px;
  margin: 0 3px;
  padding: 0;

}

.listdots .dots:hover {
  background: #25C9D0;
}

.listdots .dots.active {
  background: #25C9D0;
}

.hellowSection .icon {
  width: 82px;
  height: 82px;
  background: #fff;
  border-radius: 50%;
  line-height: 75px;
  margin: 12px auto;
}

.hellowSection h1 {
  color: #2A3950;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}



.footerSection {
  background: #fff;
  width: calc(100% - 240px);
  padding: 20px;
  position: fixed;
  bottom: 0;
}

.grid-scroll {
  overflow: scroll;
  min-height: 440px;
}

.footerSection form {
  display: flex;
  height: 56px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #E7E7E7;
  background: #F3F3F3;
}

.footerSection form input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 14px;
  color: #2A3950;
}

.footerSection form .typeBtn {
  background: url('/src/assets/img/formBtn.svg') #25C9D0 no-repeat center center;
  min-width: 32px;
  height: 32px;

}

.tripDetails {
  padding: 0 !important;
  margin-bottom: 20px;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 6px !important;
  overflow: hidden;
  transition: all .2s ease-in-out;
  height: 187px;

}

.tripDetails .tripImg {
  overflow: hidden;
  height: 100%;
}

.tripDetails .tripImg img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  object-fit: fill;
}

.tripDetails .tripImg img:hover {
  transform: scale(1.1);
}

.tripDetails .details {
  padding-left: 20px;
}

.tripDetails .details h2 {
  color: #000;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoText {
  font-size: 13px;
  color: #000;
}

.infoText .icon {
  font-size: 16px;
  margin-right: 5px;
  color: #000;
}

.infoText .icon.date {
  color: #db487e;
  margin-bottom: 0px;
}

.infoText .dealDates {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  line-height: 14px;
  color: #000;
  border: 2px solid #25C9D0;
  border-radius: 5px;
  padding: 0.5rem 1.1rem;
  background: #ffffff;
  text-transform: math-auto;
}

.infoText .dealDates:hover,
.infoText .dealDates:active {
  background-color: #25C9D0 !important;
  color: #fff !important;
  border-color: #23cad3 !important;
}

.infoText .location,
.infoText .rating,
.infoText .date {
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 16px;
  color: #000;


}

.rightDetails {
  font-size: 12px;
  text-align: center;
  background-color: #f6f6f6;
  height: 100%;
  border-right: 1px solid #dbdbdb;
  font-size: 11px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightDetails p {
  margin: 0;
}

.book-btn {
  background: #23cad3 !important;
  color: #000 !important;
  border-color: #23cad3 !important;
}

.rightDetails .book-btn {
  height: 2.7em;
  font-size: 13px;
  border: 1px solid #25C9D0;
  border-radius: 5px;
  line-height: normal;
  margin-top: 10px;
  text-transform: math-auto;

}

.price-h5 {
  font-size: 20px;
  line-height: 23px;
  color: #db487e;
  margin: 5px 0;
  display: block;
}

.nextbtn,
.nextbtn:hover {
  border-radius: 8px !important;
  background: #25C9D0 !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
  color: #fff !important;
}

.custom-scroll {
  overflow-y: scroll !important;
}

.custom-scroll::-webkit-scrollbar,
.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  width: 3px !important;
}

.custom-scroll::-webkit-scrollbar-track,
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 18px rgba(250, 250, 251, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 2px;
  margin-top: 16px;
  margin-bottom: 16px;

}

.custom-scroll::-webkit-scrollbar-thumb,
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #dfe2e6 !important;
  width: 6px !important;
}

.custom-scroll::-webkit-scrollbar-thumb:hover,
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb:hover {
  background: #757d85;
}

.aiChatbot .chatbotList {
  max-width: 500px;
  margin: 50px auto;
  margin-top: 25px;

}

.aiChatbot .chatbotList li {
  border-radius: 12px;
  background: #FFF;
  align-items: center;
  display: flex;
  list-style: none;
  gap: 15px;
  position: relative;
  border: solid 1px #fff;
}

.aiChatbot .chatbotList li .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #e8f1fc;
  width: 48px;
  height: 48px;
  transition: transform 0.3s ease-in-out;
}

.aiChatbot .chatbotList li .icon img {
  margin: 0 auto;
  border-radius: 30%;
}

.aiChatbot .chatbotList li:nth-child(1) .icon img {
  width: 20px;
}

.aiChatbot .chatbotList li:nth-child(2) .icon img {
  width: 15px;
}

.aiChatbot .chatbotList li:nth-child(3) .icon img {
  width: 32px;
}

.aiChatbot .chatbotList li p {
  color: #171A1F;
  font-size: 13px;
  transition: transform 0.3s ease-in-out;

}

.aiChatbot .chatbotList li:hover {
  border: 1px solid #25C9D0;
  box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07), 0px 0px 2px 0px rgba(23, 26, 31, 0.12);
  cursor: pointer;
}

.aiChatbot .chatbotList li:hover:after {
  display: block;
  content: "";
  background-image: url(./assets/img/list-arrowright.png);
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  animation: shake 1s cubic-bezier(.36, .07, .19, .97) both;

}

.aiChatbot .chatbotList li:hover p {
  transform: translateX(-10px);
}

.aiChatbot .chatbotList li:hover .icon {
  animation: shake 1s cubic-bezier(.36, .07, .19, .97) both;
}

.aiChatbot .chatbotList li:hover .icon {
  color: #fff;
}

.aiChatbot h1 {
  color: #171A1F;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.contentSection {
  height: calc(100vh - 100px);
  padding-bottom: 14px;
}



.customChip {
  font-size: 12px !important;
}

.customChip:hover {
  box-shadow: inset 305px 0px 0px #25C9D0;
  color: #ffffff;

}

.customCalender legend {
  display: none;
}

.customCalender label,
.customRadio label {
  font-size: 14px;
}

.customCalender fieldset {
  border-color: #25C9D0;
}

.customCalender fieldset {
  padding: 8px;
  padding-top: 5px;
}

.customCalender input {
  padding: 8px;
  padding-top: 4px;
  font-size: 14px;
}

.customRadio .days {
  border-radius: 4px;
  border: 1px solid #25C9D0;
  background: #FFF;
  padding-right: 6px;
  position: relative;
  top: -4px;
  margin-left: 0;
  margin-right: 7px;
}

.customRadio .days span:nth-child(1) svg {
  width: 15px;
}

.customRadio .days span:nth-child(1) {
  padding: 4px;
}

.customRadio .days span:nth-child(2) {
  font-size: 14px;
}

.customSubmit {
  border-radius: 4px;
  border: 1px solid #25C9D0;
  background: rgba(68, 160, 188, 0.05);
  color: #25C9D0;
  transition: all 0.5s;
  cursor: pointer;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 30px;
  margin-top: 27px;
}

.customSubmit .arrowIcon {
  display: inline-block;
  min-width: auto;
  padding: 0;

}

.customSubmit .arrowIcon:hover {
  animation: moveRight 1s infinite;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}


.customCheckBox {
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  margin-right: 10px;
  display: inline-block;
  border-radius: 32px;
  font-size: 12px;
  text-wrap: nowrap;
  align-items: center;
  padding-left: 7px;
  padding-right: 13px;
}


.customCheckBox span {
  padding: 0;
}

.customCheckBox svg {
  width: 16px !important;
}

.btn_submit {
  padding: 8px 16px !important;
  border-radius: 16px !important;
  border: 1px solid #25C9D0 !important;
  background: #25C9D0 !important;
  font-size: 12px !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.rightSide {
  width: 100%;
  position: relative;
}

.rightSideChat .chaticon {
  background: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 30px;
  text-align: center;
  margin-left: 8px;

}

.rightSideChat .chaticon img {
  max-width: 60px;
  margin-right: 0;
  display: inline-block;
}


/* New chat css  */

.options {
  padding-bottom: 15px;
}

.options .options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.options .options-container .option-item {
  display: inline-flex;
  padding: 5px 6px 5px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #E4E4E4;
  color: #565E6C;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  border: solid 1px #E4E4E4;
}

.item-active {
  color: #25C9D0 !important;
  border: solid 1px #25C9D0 !important;
  background: #fff !important;

}

.options .options-container .option-item:hover {
  color: #25C9D0;
  border: solid 1px #25C9D0;
  background: #fff;
}

.options .options-header {
  border-radius: 9px;
  background: #f0f1ff;
  display: inline-flex;
  padding: 6px 8px;
  color: #6d7fdc;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.popularPlaces h6 {
  color: #db487e;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  font-weight: bold;
  margin-top: 8px;
}

.pplist {
  padding: 0;
  margin: 0;
  color: #25C9D0;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  cursor: pointer;
  text-align: left;
  list-style: none;


}

.pplist li {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  margin-bottom: 8px;

}

.pplist li a {
  color: #000;
  text-decoration: none !important;
}

.pplist li:hover a {
  color: #db487e;
}

.pplist li .placesIcon {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #25C9D0;
  border-radius: 50%;
  position: relative;
  top: -2px;
  margin-right: 2px;
  transition: background-color 0.5s ease, width 0.5s ease, border-radius 0.5s ease;


}

.pplist li:hover .placesIcon {
  width: 16px;
  height: 5px;
  background: #db487e;
  border-radius: 8px;
  transition: background-color 0.5s ease, width 0.5s ease, border-radius 0.5s ease;

}

.rightSide .vertical-lines {
  position: absolute;
  top: 100px;
  right: 0;
  margin-right: 12px;
  cursor: pointer;
  z-index: 1;
  display: inline-block;
}

.rightSide .vertical-lines .lines {
  border-radius: 9999px;
  height: 16px;
  width: 4px;
  background-color: #25C9D0;
  cursor: pointer;
}

.rightSide .vertical-lines .lineOne {
  transform: translateY(0.15rem) rotate(0deg);
}

.rightSide .vertical-lines .lineTwo {
  transform: translateY(-0.15rem) rotate(0deg);
}

.rightSide .vertical-lines:hover .lineOne {
  transform: translateY(0.15rem) rotate(-15deg) !important;
}

.rightSide .vertical-lines:hover .lineTwo {
  transform: translateY(-0.15rem) rotate(15deg) translateZ(0px) !important;

  position: relative;
  top: 3px;
}

.rightSide .vertical-lines:hover::after {
  content: "Close sidebar";
  position: absolute;
  background-color: #000;
  font-size: 12px;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
  top: 0;
  right: 16px;
  text-wrap: nowrap;
}

.rightSide .vertical-lines:hover::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0px 8px 8px 8px;
  border-color: #000 transparent;
  display: block;
  width: 0;
  z-index: 1;
  transform: rotate(90deg);
  bottom: 13px;
  left: -18px;
}

.rightBarFull .vertical-lines:hover::after {
  content: "Open sidebar";

}

.rightBarFull .vertical-lines .lineOne,
.rightBarFull .vertical-lines:hover .lineOne {
  transform: translateY(.15rem) rotate(15deg) !important;
}

.rightBarFull .vertical-lines .lineTwo,
.rightBarFull .vertical-lines:hover .lineTwo {
  transform: translateY(.15rem) rotate(-15deg) !important;
  position: relative;
  top: -3px;
}


.rightBarFull .react-chatbot-kit-chat-input-container {
  width: calc(100% - 16px);
}

.yourOrigin .custom_Autocomplete {
  width: 200px;
}

@media (min-width:992px) and (max-width:1024px) {
  .yourOrigin .custom_Autocomplete {
    width: 150px;
  }

  .yourOrigin .custom_Autocomplete [class*="MuiAutocomplete-root"] {
    padding-right: 25px !important;
  }

}

.yourOrigin .MuiInputBase-formControl::before,
.yourOrigin .MuiInputBase-formControl::after {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background-size: contain !important;
}

.yourOrigin .MuiInputBase-formControl::before {

  background: url('/src/assets/img/location-fill.png') no-repeat;
}

.yourOrigin .MuiInputBase-formControl::after {
  background: url('/src/assets/img/iconamoon_search.png') no-repeat;
  position: absolute;
  right: 8px;
}

.yourOrigin .MuiAutocomplete-clearIndicator {
  position: relative;
  right: 24px;
  display: none;
}

.yourOrigin .MuiAutocomplete-popupIndicator {
  display: none;
}


.yourOrigin fieldset {
  border-radius: 8px;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 14px;

}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: rgb(68 160 188 / 20%) !important;
}

.weather-condition-popover .ulList {
  padding-bottom: 10px !important;

}

.weather-condition-popover .weather-place-title {
  background: #db487e;
  height: 16px;
}

.weather-condition-popover li span {
  font-size: 13px;
}

.TooltipTitle {
  color: #db487e !important;
}

.demo-simple-select-label {
  position: relative;
  top: 5px;
  left: 10px;
  background: #fff;
  z-index: 1;
  display: block;
  width: 68px;
  font-size: 13px !important;
}

#header-container {
  padding: 8px 0;
}

.WeatherReportSection {
  border-bottom: solid 1px solid 1px rgb(0 0 0 / 30%);
  background-color: #F6FFFF;
  color: #000;
  padding: 16px;
  border-radius: 8px;
  position: relative;
}

.WeatherReportSection::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #F6FFFF;
  transform: translateY(-50%);
}

.placesListSection {
  padding: 16px;
  border-bottom: solid 1px solid 1px rgb(0 0 0 / 30%);
  background-color: #F6FFFF;
  color: #000;
  border-radius: 8px;
  position: relative;
}

.placesListSection::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #F6FFFF;
  transform: translateY(-50%);


}

@media screen and (max-width:900px) {

  .WeatherReportSection {
    right: -18px !important;
    border-radius: 5px;

  }

  .placesListSection::before {
    content: "";
    position: inherit;
    top: none;
    left: none;
    border-top: none;
    border-bottom: none;
    border-right: none;
    transform: none;
  }


  .placesListSection::after {
    content: "";
    position: absolute;
    bottom: -14px;
    right: 14px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #F6FFFF;
    transform: translateY(50%);
    rotate: -90deg;
  }

  .placesListSection {
    bottom: -10px !important;
    border-radius: 2px;

  }

}

@media screen and (max-width:768px) {
  .WeatherReportSection {
    right: -25px !important;
  }
}


.WeatherReportSection .avg-title {
  font-size: 13px;
  font-weight: bold;
}

.WeatherReportSection .weatherConditions {
  position: relative;
}

.WeatherReportSection .weatherConditions .btn-arrow {
  font-size: 12px;
  top: 9px;
  left: -9px;
}

.WeatherReportSection .destinationName {
  width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.WeatherReportSection .precipitationValue {
  color: #25C9D0;
  font-size: 16px;
}

.btn-cancel {
  opacity: 1;
  color: #000;
  border-radius: 50%;
  margin: 0 0 0 auto;
  text-decoration: none;
  cursor: pointer;

}

.captchaSection {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.results-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: #ffffff;
  line-height: 1.4;
  font-family: Roboto, Helvetica Neue, sans-serif;
  border-radius: 0;
  overflow: hidden;
  z-index: 0;
}

@media (min-width: 769px) {
  .results-card {
    flex-direction: row;
  }
}

.results-card .description {
  flex-basis: 70%
}

.results-card .meta {
  flex-basis: 30%
}

.AccordionDetails {
  padding: 0 !important;
}

.price-disclmr-note {
  font-size: 9px;
  padding: 0px;
  margin: 0px;
  display: block;
}


/* Bouncing Loader */
.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 40px auto;
}

.bouncing-loader>div {
  width: 12px;
  height: 12px;
  margin: 2px 5px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
  margin-top: 8px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    /* transform: translateY(-16px); */
    transform: translateY(-10px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

.bouncing-loader-conditional-cls {
  justify-content: flex-start;
  margin: 10px auto;
  margin-bottom: 4px;
}

/*** RWD css ***/


/* Mobile devices */

@media (max-width: 575px) {
  .tripDetails .details {
    padding-bottom: 8px;
  }

  .tripDetails .details h2 {
    font-size: 14px;
    white-space: unset;
    line-height: 22px;
  }
}

@media (max-width: 768px) {

  .header-section .logo img {
    width: 80px;
  }

  .header-section .btn-cancel {
    display: none;
  }

  .hero-section .home-heading {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .yourOrigin .custom_Autocomplete {
    width: 150px;
  }

  .yourOrigin .custom_Autocomplete .MuiAutocomplete-input {
    padding: 1px !important;
    font-size: 12px;
  }

  .aiChatbot .chatbotList {
    max-width: 100%;
  }

  .react-chatbot-kit-chat-bot-message-container,
  .react-chatbot-kit-user-chat-message {
    max-width: 80% !important;
  }

}

@media (max-width: 992px) {

  .tripDetails {
    height: auto;
    overflow: auto;
  }

  .leftSideBar.open {
    position: absolute;
    right: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  }

  .vertical-lines-mobile {
    display: none !important;
  }

}

@media (max-width: 1199.98px) {
  #header-container.container {
    max-width: 100% !important;
    padding: 8px 16px !important;
  }

  .travelHelpcontainer {
    width: 100%;
  }
}


/* xxl  applies to x-large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  display: none !important;
}

::-webkit-scrollbar {
  /* width: 3px !important; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 18px rgba(250, 250, 251, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 2px;
  margin-top: 16px;
  margin-bottom: 16px;

}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #dfe2e6 !important;
  width: 6px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #757d85;
}

.disabledDiv {
  pointer-events: none !important;
  opacity: 0.8 !important;
}

.margin-top-100 {
  margin-top: 78px;
}

@media screen and (max-width:768px) {
  .margin-top-100 {
    margin-top: 61px;
  }
}

.AIslider-plus-item {
  width: 100%;
  /* min-height: 300px !important; */
  min-height: 460px !important;

  background-color: #F6F6F6 !important;
}

.plus-btn-class {
  border: 0px !important;
  background-color: transparent !important;
  cursor: pointer !important;
}

.plus-link-btn {
  text-decoration: none !important;
  cursor: pointer !important;

}



.disable-select {
  user-select: none;
  -webkit-touch-callout: none;
}

.AIslider-plus-item {
  width: 100%;
  min-height: 440px !important;
  background-color: #ffffff !important;
}

.plus-btn-class {
  border: 0px !important;
  background-color: transparent !important;
}

.plus-link-btn {
  text-decoration: none !important;
  background-color: transparent !important;
  border: 0px !important;

}

.plus-btn-card-class {
  width: 245px;
  /* height: 190px; */
  border-radius: 10px;
  border: 2px;
  background-color: #EEFEFF;
  border-color: #25C9D0;
  border-style: dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.show-more-packages-btn-class {
  background-color: transparent;
  border: 2px solid #25C9D0;
  border-radius: 5px;
  color: #1ABCC3;
  font-size: 12px;
  font-weight: 500;
}

.show-more-packages-paragraph-class {
  color: #1ABCC3;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.preferences-radio-btn-class span {
  font-size: 12px !important;
  color: #565E6C !important;
  font-weight: 400 !important;
}

.ai-preferences-options {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.react-datepicker-container-class .react-datepicker {
  width: 100% !important;
  border: 0px !important;
}

.react-datepicker-container-class .react-datepicker .react-datepicker__month-container {
  float: none !important;
}

@media screen and (min-width:576px) and (max-width:767px) {
  .plus-btn-card-class {
    width: 205px;
  }
}

@page {
  size: auto;
  margin: 20px;
  border-radius: 16px;
  background: #F5F6FA;
  padding-bottom: 16px;
  padding-top: 30px;
  padding-left: 20px;
  position: relative;
  flex: 1 1 auto;
}

@media print {
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-0.5* var(--bs-gutter-x));
    margin-left: calc(-0.5* var(--bs-gutter-x));
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .heading-grid {
    color: #000;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 1.5rem;
  }

  .gap-3 {
    gap: 1rem !important;
  }

  .d-flex {
    display: flex !important;
  }

  .custom-stepper {
    width: 100%;
  }

  .custom-stepper .stepper-item {
    list-style: none;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .custom-stepper .stepper-item::after {
    content: '';
    height: 100%;
    border: dashed 1px rgb(0 0 0 / 50%);
    width: 1px;
    display: block;
    position: absolute;
    top: 17px;
    left: 12px;
  }

  .custom-stepper .stepper-item .stepper-icon .icon {
    z-index: 1;
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 20px;
    background-color: #DB487E;
    color: #fff;
    text-align: center;
  }

  .custom-stepper .stepper-item .stepper-icon .icon:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 19px;
    left: 50%;
    margin-left: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 20px solid #DB487E;
  }

  .custom-stepper .stepper-item .stepper-icon .icon span {
    position: relative;
    top: 5px;
    font-size: 13px;
  }

  .custom-stepper .last-item::after {
    display: none !important
  }


  .dragging-box .custom-stepper .stepper-item::after {
    display: none !important
  }

  .dragging-box .custom-stepper .stepper-item .trip-duration {
    display: none !important
  }

  .dragging-box .custom-stepper .stepper-item .stepper-content .trip-card .description {
    border-color: #25C9D0;
  }

  .custom-stepper .stepper-item .stepper-content {
    width: 100%;
  }

  .custom-stepper .stepper-item .stepper-content::after {
    content: '';
    height: 100%;
    width: 2px;
    background-color: red;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    line-height: 1.4;
    border-radius: 0;
    overflow: hidden;
    z-index: 0;
  }

  @media (min-width: 769px) {
    .custom-stepper .stepper-item .stepper-content .trip-card {
      flex-direction: row;
    }
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description {
    flex-basis: 85%;
    background: #fff;
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta {
    flex-basis: 15%
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description h2 {
    color: #DB487E;
    text-align: center;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .draggable-icon {
    position: absolute;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .title {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.412px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .dec {
    color: #4A4A4A;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .price-book {
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
    align-items: center;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .price-book .price {
    color: #DB487E;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.412px;
    margin-bottom: 8px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .price-book .book-btn {
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.412px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .border-right {
    border-right: solid 1px #DFDFDF;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .timer-icons {
    position: relative;
    color: #7A7C7D;
    font-size: 12px;
    font-weight: 400;
    margin-right: 16px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .timer-icons .activity-time {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin: 10px 0
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .timer-icons::before {
    content: "";
    display: block;
    height: 62%;
    position: absolute;
    top: 16px;
    left: 7px;
    border: dashed 1px #DFDFDF;
    margin-right: 0;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .description .timer-icons .timer-bottom,
  .custom-stepper .stepper-item .stepper-content .trip-card .description .timer-icons .timer-top {

    font-size: 15px;
    color: #DB487E;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta .image-grid {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    overflow: hidden;
    position: relative;
    width: 190px;
    height: 137px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);

      img {
        transform: scale(1.1);
      }
    }
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta .image-grid img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;

  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta .image-grid .image-info {
    position: absolute;
    bottom: 0;
    padding: 8px;
    width: 100%;
    border-radius: 0px 0px 16px 16px;
    background: rgb(0 0 0 / 50%);
    color: #FFF;
    font-size: 11px;
    font-weight: 400;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta .del-edit-icons {
    width: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  }

  .custom-stepper .stepper-item .stepper-content .trip-card:hover .meta .del-edit-icons {
    width: 16px;
    opacity: 1;
    visibility: visible;
  }


  .custom-stepper .stepper-item .stepper-content .trip-card .meta .del-edit-icons button {
    min-width: auto;
    padding: 0;
    font-size: 20px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-card .meta .del-edit-icons .btn-del-icon {
    width: 20px;
    height: 20px;
    color: #DB487E;
  }



  .custom-stepper .stepper-item .stepper-content .trip-card .meta .del-edit-icons .btn-edit-icon {
    color: #4dd2d7;
  }

  .custom-stepper .stepper-item .stepper-content .trip-duration {
    color: #070F1D;
    font-size: 14px;
    font-weight: 400;
    height: 70px;
    position: relative;
    padding-top: 25px;
    padding-left: 129px;
  }

  .custom-stepper .stepper-item .stepper-content .trip-duration::before {
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    border: dashed 1px #C2C2C2;
    width: 1px;
  }


  .custom-stepper .stepper-item .stepper-content .trip-duration .car {
    width: 36px;
    height: 20px;
    display: flex;
    justify-content: space-between;
  }

  .custom-stepper .stepper-item .stepper-content .trip-duration .wheel {
    position: relative;
    animation: spin 5s linear infinite;
  }

  .custom-stepper .stepper-item .stepper-content .trip-duration .wheel-one {
    left: 2px;
    top: 11px;
    animation: spin 5s linear infinite;
    position: relative;
  }

  .custom-stepper .stepper-item .stepper-content .trip-duration .wheel-two {
    left: -2px;
    top: 11px;
    position: relative;
    animation: spin 5s linear infinite;

  }

}

.disable-del-edit-icons {
  display: none !important;
  opacity: 0 !important;
}

@media screen and (min-width:993px) {
  .trip-mobile-menu-icon {
    display: none;
  }
}

@media screen and (max-width:450px) {
  .mobile-padding-cls {
    padding-left: 5px !important;
    padding-right: 5px !important;

  }
}


.highlight-card {
  border: 2px solid #007bff;
  background: #f0f8ff;
}
.button.chatbot-chat-btn-send.mic-icon {
  right: 46px;
}

/* .button.chatbot-chat-btn-send.mic-icon svg {
  font-size: 20px;
} */