body {
    background-color: #fff !important;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}



body,
.aishoppingresultdata {
    overflow-y: scroll !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;

}

body::-webkit-scrollbar,
.aishoppingresultdata::-webkit-scrollbar {
    width: 3px !important;
}

body::-webkit-scrollbar-track,
.aishoppingresultdata::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 18px rgba(250, 250, 251, 0.3);
    -webkit-border-radius: 5px;
    border-radius: 2px;
    margin-top: 16px;
    margin-bottom: 16px;
}

body::-webkit-scrollbar-thumb,
.aishoppingresultdata::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #25C9D0 !important;
    width: 6px !important;
}

body::-webkit-scrollbar-thumb:hover,
.aishoppingresultdata::-webkit-scrollbar-thumb:hover {
    background: #757d85;
}


.chatbot-user-chat-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-end;
}


.chatbot-user-chat-message {
    border-radius: 12px 12px 0px 12px;
    /* border: 1px solid #BEBFD3; */
    border: 1px solid #1B9298;
    /* background: #DEEBFF; */
    background: #F5FEFF;
    max-width: 50%;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    font-size: 0.9rem;
    color: #585858;
    position: relative;
    text-align: left;
    overflow: hidden;
    word-wrap: break-word;
    /* word-break: break-all; */
    hyphens: auto;
    -webkit-hyphens: auto;
    /* -webkit-word-break: break-all; */
    -webkit-word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: normal;


}

.chatbot-user-chat-message::first-letter,
.chatbot-chat-bot-message::first-letter {
    text-transform: uppercase;
}

.chatbot-user-chat-message-arrow,
.chatbot-chat-bot-message-arrow {
    display: none;
}

.chatbot-user-chat-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #f1f1f1;
    position: absolute;
    right: -7px;
    top: 13px;
}

.chatbot-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-user-avatar-icon {
    fill: #fff;
    width: 100%;
}

.chatbot-chat-bot-message-container {
    max-width: 60%;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
}

.chatbot-chat-bot-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-chat-bot-avatar-container img {
    width: 100%;
}

.chatbot-chat-bot-avatar~.chatbot-chat-bot-message {
    margin-left: 0;
}

.chatbot-chat-bot-message {
    width: 90%;
    color: #171A1F;
    border-radius: 12px 12px 12px 0px;
    border: 1px solid #BEBFD3;
    padding: 10px;
    font-size: 0.9rem;
    position: relative;
    text-align: left;
}

.chatbot-chat-bot-message-voice-icon {
    position: absolute;
    right: -9px;
    top: -9px;
    background: #DB487E;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-chat-bot-message-voice-icon svg {
    color: white;
    display: inline-block;
    font-size: 16px;
    /* margin: 6px 4px; */
}

.AIoptions {
    padding-bottom: 15px;
}

.AIoptions .AIoptions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.AIoptions .AIoptions-container .AIoptions-item {
    display: inline-flex;
    padding: 5px 6px 5px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    background: #E4E4E4;
    color: #565E6C;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-right: 4px;
    margin-bottom: 8px;
    cursor: pointer;
    border: solid 1px #E4E4E4;
}

.AIoptions-item.item-active {
    color: #25C9D0 !important;
    border: solid 1px #25C9D0 !important;
    background: #fff !important;

}

.AIoptions .AIoptions-container .AIoptions-item:hover {
    color: #25C9D0;
    border: solid 1px #25C9D0;
    background: #fff;
}

.AIoptions .AIoptions-header {
    border-radius: 9px;
    background: #f0f1ff;
    display: inline-flex;
    padding: 6px 8px;
    color: #6d7fdc;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}


.AIcustomCheckBox,
.AIcustomCheckBox:hover {
    height: 32px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    /* margin-right: 10px !important; */
    display: inline-block;
    border-radius: 32px !important;
    font-size: 12px;
    text-wrap: nowrap;
    align-items: center;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: 1px solid #25C9D0;
}


.AIcustomCheckBox span {
    padding: 0;
}

.AIcustomCheckBox svg {
    width: 16px !important;
}


/* sliders css  */

.AIchatBg {
    color: #171A1F;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

}


.AItripDetails {
    width: 95%;
    margin: 0 auto;
}

.AIresults-card {
    transition: all 0.3s ease;
    /* cursor: pointer; */
}

.AIresults-card:hover {
    transition: all 0.3s ease;
    transform: translateY(-3px);
    box-shadow: #ddd 0 20px 25px -5px, #ddd 0 10px 10px -5px;
}

.AIresults-card .AImeta {
    height: 150px;
}

.AIresults-card .destination-on-img {
    width: fit-content;
    border-radius: 16px;
    color: #fff;
    background-color: #db487e;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .5px;
    padding: 3px 10px;
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    display: flex;
    align-items: start;
    gap: 2px;
    z-index: 1;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}


.AIresults-card .destination-on-img .FmdGoodIcon {
    width: 13px;
    height: 13px;
    font-size: 12px;
    color: #fff;
    position: relative;
    top: -2px;

}


.AItripDetails .AItripImg {
    overflow: hidden;
    height: 100%;
    position: relative;
}

.AItripDetails .AItripImg img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
}

.AItripDetails .AItripImg img:hover {
    /* transform: scale(1.1); */
}

.AItripDetails .AItripImg img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.AItripDetails .AIdetails h2 {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    height: 47px;
    /* margin-top: 8px; */
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIdescription .grid-space {
    padding: .7rem 1rem 0rem !important;
}

.AIinfoText {
    font-size: 13px;
    color: #000;
}

.AIinfoText .AIlocation {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIinfoText .AIrating {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIinfoText .AIdate {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}


.AIrightDetails .AIlocation span,
.AIinfoText .AIrating span,
.AIinfoText .AIdate span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIinfoText .icon {
    font-size: 18px;
    margin-right: 5px;
    color: #000;
}

.font-Roboto {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIinfoText .AIrating .icon {
    font-size: 12px;
}

.AIinfoText .AIdealDates {
    text-decoration: none;
    line-height: 14px;
    padding: 12px;
    text-transform: math-auto;
    width: 100%;
    background: #ffffff;
    border: 2px solid #25c9d0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    border-radius: 4px;
    white-space: nowrap;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIinfoText .AIdealDates:hover {
    background-color: #25c9d0;
    color: #000
}

.AIrightDetails {
    font-size: 13px;
    /* text-align: center; */
    background-color: #f0f0f0;
    height: 100%;
    border-right: 1px solid #dbdbdb;
    color: #000;
    position: relative;
    margin: -16px;
    margin-top: 8px;
    padding: .5rem 1rem .7rem;
    margin-bottom: 0;
}

.AIrightDetails p {
    margin: 0;
}

.AIprice-h5 {
    /* font-size: 20px;
    line-height: 23px;
    color: #db487e;
    margin: 5px 0;
    display: flex;
    letter-spacing: -1px; */

    color: #db487e;
    font-size: 26px;
    margin-right: 4px;
    font-weight: 700 !important;
    position: relative;
    top: -8px;


}

.AIrightDetails .from-text {
    font-size: 12px;
    color: #454545;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;

}

.AIprice-h5 .dollar-icon {
    position: relative;
    top: 1px;
    font-size: 22px;
    left: -5px;
}

.AIprice-h5 .packagePrice {
    /* position: relative;
    left: -8px; */
    /* font-family: 'Roboto','Helvetica Neue', 'sans-serif' !important; */
}

.per-person-para-cls {
    color: #454545;
    font-weight: 500;
    font-size: 11px;
    color: #454545;
    line-height: 1.1;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.AIrightDetails .price-disclmr-note {
    font-size: 11px;
    color: #454545;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;


}

.AIrightDetails .getDifferentDays {
    font-size: 11px;
    color: #454545;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
    margin-bottom: 4px;

}

.AIrightDetails .book-the-deal {
    /* padding: .6rem 1rem 0px; */
    padding: 0.6rem 1rem 0 0.6rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px #c6c6c6;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;

}

.AIrightDetails .book-the-deal .book-this-deal {
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
    margin-bottom: .2rem !important;
    font-size: 1rem;
    font-weight: 500;


}

.AIinfoText .AIdate .icon {
    color: #db487e;
    font-size: 16px;
}

.AIprice-margin-cls {
    margin-bottom: 4px !important;
    display: flex;
}

.AIrightDetails .AIbook-btn,
.AIrightDetails .AIbook-btn:hover,
.AIrightDetails .AIbook-btn:focus-visible {
    height: 2.7em;
    font-size: 12px;
    border: 1px solid #25C9D0;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    line-height: normal;
    margin-top: 10px;
    text-transform: math-auto;
    background: #25C9D0;
    color: #000;
    /* position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%); */
    width: 55px;
    height: 83px;
    margin: -16px;
}

.AIrightDetails .AIbook-btn .icon {
    font-size: 18px;
    width: 20px;
    height: 20px;

}

.AIrightDetails .AIweather-places {
    font-size: 22px;
    display: flex;
    gap: 16px;
    justify-content: center;
    padding-top: 8px;
    margin-bottom: 8px;
    color: #000;
}

.AIrightDetails .AIweather-places .icons {
    cursor: pointer;
}

.AIweather-places .chatbot-chat-bot-message-voice-icon {
    width: 18px;
    height: 18px;
}

.AIweather-places .chatbot-chat-bot-message-voice-icon svg {
    font-size: 14px;
}

.AIrightDetails .AIweather-places .icons:nth-child(1) {
    color: #25C9D0;
}

.AIrightDetails .AIweather-places .icons:nth-child(2) {
    color: #DB487E;
}



.slick-slide>div:first-child {
    width: 95%;
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-bottom: 25px;
}


.slick-prev,
.slick-next {
    color: #000 !important;
    width: 30px !important;
    height: auto !important;
}

.slick-prev {
    left: -35px !important;
}

.slick-next {
    right: -40px !important;
}

.slick-disabled {
    opacity: 0.1;
    pointer-events: none;
}

.AiShoppingResult h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 45.5px;
    font-family: "Agbalumo" !important;
    color: #cc4173;
    text-shadow: none;
    width: 750px;
    animation: typing 4s steps(50000), blink .5s step-end infinite alternate !important;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
}

.ai-assistant-desc-cls {
    font-size: 20px;
    font-weight: 380;
    line-height: 30px;
    color: #333 !important;
    text-shadow: none;
    width: 600px;
    white-space: wrap;
    overflow: hidden;
    margin: 0 auto;
}

/* 
.ai-assistant-title-cls {
    font-size: 48px;
    font-weight: 400;
    line-height: 45.5px;
    font-family: "Agbalumo" !important;
    color: #cc4173 !important;
    text-shadow: none;
} */

.AiShoppingResult h1::after {
    display: block;
    contain: "...";
    width: 100px;
}


@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

.AiShoppingResult h1 sup {
    font-size: 18px;
    font-weight: 400;
}

.AiShoppingResult h1 .badge {
    background: #DB487E;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    border-radius: 26px;
    padding: 5px 16px;
    position: relative;
    top: -5px;

}

.dialog-content-padding-cls {
    padding-bottom: 0px !important;
}

.chatbot-input-container-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: calc(100vh - 210px); */
    height: calc(100vh - 380px);
    animation: moveUp 1s forwards;
}

.chatbot-input-container-align-bottom {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 16px;
    animation: moveDown 1s forwards;
    /* background: #F3F3F3; */
    background-color: #fff;
    /* height: calc(100vh - 210px); */
}

@keyframes moveDown {
    0% {
        transform: translateY(-50vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(40vh);
    }

    100% {
        transform: translateY(0vh);
    }
}


.chatbot-input-form {
    position: relative;
    /* width: 85%; */
    text-align: center;
    margin: 0 auto;
}


.chatbot-input {
    padding: 10px 20px;
    border-radius: 6px;
    border: 1px solid #25C9D0;
    background: #FFF;
    width: 100%;
    font-size: 16px;
    color: #05212A;
    font-weight: 400;
    animation: 2s ease-out;
    padding-right: 50px;
}

.chatbot-input:focus-visible {
    outline: 1px solid #25C9D0;
}

.chatbot-chat-btn-send {
    position: absolute;
    right: -6px;
    top: 7px;
    background: transparent !important;
    transition: transform 0.3s ease-in-out;
    width: 60px;
    border: none;
    border-bottom-right-radius: 5px;
    color: #999999;
}

.chatbot-chat-btn-send.mic-icon {
    right: 32px;
}

.chatbot-chat-btn-send.mic-icon svg {
    font-size: 18px;
}

.chatbot-kit-input-container .cta-btn {
    position: relative;
    display: inline-block;
    color: #666666;
    background: transparent;
    text-transform: none;
    padding: 5px 40px;
    white-space: nowrap;
    font-size: 0.775rem;
    border-radius: 5px;
    z-index: 1;
    overflow: hidden;
    font-weight: 500;
}

.chatbot-kit-input-container .cta-btn::before,
.chatbot-kit-input-container .cta-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    z-index: -1;
}

.chatbot-kit-input-container .cta-btn::before {
    /* background: linear-gradient(to right, #25c9d0, #db487e); */
    background: linear-gradient(to right, #25C9D0, #fbee77, #DB487E);
    padding: 1px 1px;
    box-sizing: border-box;
}

.chatbot-kit-input-container .cta-btn::after {
    background: #fff;
    margin: 1px 1px;
    box-sizing: border-box;
}


.chatbot-kit-input-container .cta-btn:hover {
    /* background: #25C9D0;
    color: #000; */
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .24);
}

.chatbot-input::placeholder {
    font-size: 14px;
}

.chatbot-input:focus+.chatbot-chat-btn-send .chatbot-chat-btn-send-icon {
    color: #25C9D0;
}

.chatbot-input-form .buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    margin-top: 8px;
}

.narrow-suggestion-large-screens-text-cls {
    max-width: 150px;
}

.dropdown-box {
    position: absolute;
    background: #fff;
    width: 90%;
    height: calc(100% - 50px);
    left: 0;
    right: 0;
    top: 40px;
    z-index: 1;
    text-align: left;
    font-size: 14px;
    padding: 10px;
    margin: 0 auto;
    border: solid 1px rgb(0 0 0 / 20%);
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.aishoppingresultdata {
    margin-top: 80px;
    height: calc(100vh - 310px);
    overflow: scroll;
}

.aishoppingresultdata-height-cls {
    height: calc(100vh - 350px);
}

.aishoppingresultdata-height-fixing-based-on-disclaimer-cls {
    height: calc(100vh - 295px);
    /* height:100vh; */

}

.aishoppingresultdata-height-fixing-based-on-disclaimer-cls.enablenotice {
    height: calc(100vh - 380px);
}

.aishoppingresultempty {
    overflow: auto;
    margin-top: 120px;
}

.slick-dots {
    top: -35px;
    left: auto;
    right: 0;
    width: auto;
    height: auto;

}

.slick-dots li {
    width: auto;
    height: auto;
}

.slick-dots li button:before {
    display: none;
}

.slick-dots li button {
    background: rgb(0 0 0 / 30%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: background-color 0.5s ease, width 0.5s ease, border-radius 0.5s ease;

}

.slick-dots li.slick-active button {
    background-color: #25C9D0;
    width: 22px;
    border-radius: 8px;
    transition: background-color 0.5s ease, width 0.5s ease, border-radius 0.5s ease;
}

.MuiTooltip-tooltip {
    background: transparent;
}







@media (max-width: 992px) {
    .AiShoppingResult h1 {
        font-size: 40px;
        animation: none !important;
        width: 700px;
        word-wrap: normal;
        white-space: pre;
        line-height: 30px;
        word-wrap: break-word;
    }
}

/* For mobile devices: */
@media (max-width: 768px) {
    .voice-enable-button-m {
        right: -9px;
    }

    .AiShoppingResult h1 {
        font-size: 25px;
        animation: none !important;
        width: auto;
        word-wrap: normal;
        white-space: pre;
        line-height: 35px;
        word-wrap: break-word;
    }

    .AiShoppingResult h1 .badge {
        padding: 5px 8px;
        font-size: 9px;
        line-height: 4px;
        top: -3px;
    }

    .ai-assistant-desc-cls {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 20px;
        color: #333 !important;
        text-shadow: none;
        width: 400px;
        white-space: wrap;
        overflow: hidden;
        margin: 0 auto;
    }

    .chatbot-skeleton-input {
        height: 32px;
    }

    .chatbot-skeleton-btn {
        height: 32px;
    }

    .AiShoppingResult h1 sup {
        font-size: 12px;
        top: -1em;
    }

    .chatbot-input {
        display: block;
        width: 90%;
    }

    .chatbot-input::placeholder {
        font-size: 9px;
    }

    .chatbot-chat-btn-send.mic-icon {
        right: 70px;
    }

    .chatbot-chat-btn-send {
        right: 31px;
    }

    .aishoppingresultdata {
        /* height: calc(100dvh - 250px) !important; */
        /* height: calc(100dvh - 240px) !important; */

    }

    .aishoppingresultdata.enablenotice {
        /* height: calc(100dvh - 250px) !important; */
        height: calc(100dvh - 300px) !important;

    }

    .chatbot-chat-bot-message-container {
        max-width: 70%;
    }

    .chatbot-chat-bot-message-container {
        max-width: 90%;
    }

    .chatbot-user-chat-message,
    .chatbot-chat-bot-message-container {
        max-width: 80%;
    }

    .slick-dots {
        display: none !important;
    }

    .slick-slide>div:first-child {
        box-shadow: 8px 9px 5px -2px rgba(0, 0, 0, 0.15);
        margin-left: 8px;
    }

    .slick-prev,
    .slick-next {
        background: #fff;
        border-radius: 50%;
        padding: 8px;
        border: solid 1px rgb(0, 0, 0, 0.2);
    }

    .slick-prev {
        left: -24px !important;
    }

    .slick-next {
        right: -24px !important;
    }

    /* .AIrightDetails .stay-icons .sicon {
        display: none;
    } */

    .footer-mobile-cls {
        display: none !important;
    }

    .AIchatBg {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .AIresults-card .destination-on-img {
        font-size: 11px;
    }

    .AIrightDetails .AIbook-btn,
    .AIrightDetails .AIbook-btn:hover,
    .AIrightDetails .AIbook-btn:focus-visible {
        width: 50px;
    }
}

.narrow-suggestion-large-screens-text-cls {
    display: block !important;
}

.narrow-suggestion-mobile-text-cls {
    display: block !important;
    /* font-size: 12px; */
    /* font-weight: 400; */
}

@media (max-width: 992px) {

    .chatbot-kit-input-container .cta-btn {
        width: auto;
        /* border-radius: 10px; */
        /* border-radius: 5px; */
        min-width: auto !important;
        font-size: 12px;
        height: 35px;
        /* height: 45px; */
        border-radius: 6px;
        padding-left: 16px;
        padding-right: 16px;

    }

    .aishoppingresultdata {
        /* height: calc(100dvh - 260px); */
        height: calc(100dvh - 380px);
    }

    .aishoppingresultdata-height-fixing-based-on-disclaimer-cls {
        height: calc(100dvh - 320px);

    }

    .aishoppingresultdata-height-fixing-based-on-disclaimer-cls.enablenotice {
        height: calc(100dvh - 380px);

    }

    .chatbot-input-container-align-bottom {

        animation: none !important;
    }

    .chatbot-input-form .buttons-group {
        justify-content: left;
        /* justify-content: center; */
    }

    .chatbot-input-form .buttons-group {
        overflow-x: auto !important;
        margin-top: 8px;
        padding: 0 10px;
        padding-bottom: 8px;
    }

    .chatbot-input-form .buttons-group::-webkit-scrollbar,
    .slick-list::-webkit-scrollbar {
        width: 3px !important;
        height: 3px !important;
    }

    .chatbot-input-form .buttons-group::-webkit-scrollbar-track,
    .slick-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 18px #e0e1e2;
        -webkit-border-radius: 5px;
        border-radius: 2px;

    }

    .chatbot-input-form .buttons-group::-webkit-scrollbar-thumb,
    .slick-list::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background-color: #999999 !important;
        width: 2px !important;
        height: 2px !important;
    }

    .chatbot-input-form .buttons-group::-webkit-scrollbar-thumb:hover,
    .slick-list::-webkit-scrollbar-thumb:hover {
        background: #757d85;
    }

}

@media (max-width: 1200px) {

    .narrow-suggestion-large-screens-text-cls {
        display: none !important;
    }

    .narrow-suggestion-mobile-text-cls {
        display: block !important;
    }

    .chatbot-input-form .buttons-group {
        margin-top: 8px !important;
    }

    .chatbot-kit-input-container .cta-btn {
        width: auto;
        /* border-radius: 10px; */
        /* border-radius: 5px; */
        min-width: auto !important;
        font-size: 12px;
        /* height: 45px; */
        height: 35px;
        border-radius: 6px;
        padding-left: 16px;
        padding-right: 16px;

    }
}

.bouncing-loader-bot-img {
    position: relative;
    top: -15px;
}

.ai-assistant-titles-container-cls {
    /* width: 750px; */
    margin: 0 auto;
}

.footer-position-cls {
    width: 100%;
    position: absolute;
    bottom: 15px;
}

@media (max-width: 768px) {
    .chatbot-kit-input-container .cta-btn {
        font-size: 10px;
        height: 30px;
    }

}

@media (max-width: 575px) {
    .AiShoppingResult h1 {
        font-size: 21px;
    }

    .ai-assistant-desc-cls {
        font-size: 0.7rem;
        width: 320px;

    }
}

@media (max-width: 374px) {
    .AiShoppingResult h1 {
        white-space: wrap
    }

    .ai-assistant-desc-cls {
        font-size: 0.7rem;
        width: 280px;

    }
}
